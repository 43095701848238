import React, { useContext, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { GlobalDispatchContext } from "../context/GlobalContextProvider"

const Notification = ({ type, message }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const [slideAndFadeOut, setSlideAndFadeOut] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setSlideAndFadeOut(true)
      setTimeout(function () {
        dispatch({ type: "TOGGLE_NOTIFICATION" })
      }, 300)
    }, 5000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <>
      <div
        className={
          slideAndFadeOut
            ? `notification-wrapper fade-out notification is-${type}`
            : `notification-wrapper notification is-${type}`
        }
      >
        <button
          onClick={() => {
            setSlideAndFadeOut(true)
            setTimeout(function () {
              dispatch({ type: "TOGGLE_NOTIFICATION" })
            }, 300)
          }}
          className="delete"
        ></button>
        <p>{message}</p>
      </div>
    </>
  )
}

Notification.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

export default Notification
