import React from "react"
import PropTypes from "prop-types"

const PageTitle = ({ title }) => {
  return (
    <>
      <div className="columns is-centered mb-4">
        <div className="column is-narrow">
          <h1 className="has-text-centered page-title">{title}</h1>
          <hr />
        </div>
      </div>
    </>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

export default PageTitle
