import React from "react"
import Seo from "../components/seo"
import PageTitle from "../components/PageTitle"
import Form from "../components/Form"
import siteCardImage from "../../static/card.jpg"

const Contact = () => {
  const shareCardGenerel = {
    src: siteCardImage,
    width: 1200,
    height: 628,
  }
  return (
    <>
      <Seo title="راسلني" image={shareCardGenerel} url="/contact/" />
      <PageTitle title="راسلني" />
      <Form />
    </>
  )
}

export default Contact
