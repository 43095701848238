import React, { useState, useContext } from "react"
import { useFormik } from "formik"
import Notification from "./Notification"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"

const initialValues = {
  sender: "",
  email: "",
  message: "",
}

const validate = (values) => {
  let errors = {}
  if (!values.sender) {
    errors.sender = "ادخل اسم المرسل!"
  }

  if (!values.email) {
    errors.email = "ادخل عنوان بريدك الإلكتروني!"
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "عنوان البريد الإلكتروني المُدخل غير صحيح!"
  }

  if (!values.message) {
    errors.message = "ادخل محتوى الرسالة!"
  }

  return errors
}

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Form = () => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...values }),
      })
        .then(() => {
          formik.resetForm()
          dispatch({ type: "TOGGLE_NOTIFICATION" })
          setNotificationMessage(
            "تم إرسال رسالتك بنجاح، سأحاول الرد عليك فور اطلاعي عليها، شكراً لك."
          )
          setNotificationType("success")
        })
        .catch((erroe) => {
          dispatch({ type: "TOGGLE_NOTIFICATION" })
          setNotificationMessage(
            "حدث خلل أثناء إرسال الرسالة، يُرجى المحاولة في وقت لاحق."
          )
          setNotificationType("danger")
        })
    },
    validate,
  })

  const [notificationType, setNotificationType] = useState("")
  const [notificationMessage, setNotificationMessage] = useState("")

  return (
    <>
      {state.toggleNotification && (
        <Notification type={notificationType} message={notificationMessage} />
      )}
      <div className="form-wrapper">
        <p>تواصل معي، للاستفسارات أو الاستشارات في مجال تجربة المستخدم..</p>
        <form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={formik.handleSubmit}
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className="field">
            <label htmlFor="sender" className="label">
              الاسم
            </label>
            <div className="control">
              <input
                name="sender"
                className="input"
                type="text"
                placeholder="اسم المرسل"
                id="sender"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.sender}
              />
            </div>
            {formik.touched.sender && formik.errors.sender ? (
              <p className="help is-danger is-size-6">{formik.errors.sender}</p>
            ) : null}
          </div>
          <div className="field">
            <label htmlFor="email" className="label">
              البريد الإلكتروني
            </label>
            <div className="control">
              <input
                form="novalidatedform"
                name="email"
                className="input"
                type="email"
                placeholder="ادخل عنوان بريدك الإلكتروني"
                id="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
            </div>
            {formik.touched.email && formik.errors.email ? (
              <p className="help is-danger is-size-6">{formik.errors.email}</p>
            ) : null}
          </div>
          <div className="field">
            <label htmlFor="message" className="label">
              محتوى الرسالة
            </label>
            <div className="control">
              <textarea
                name="message"
                className="textarea"
                placeholder="اكتب نص رسالتك"
                id="message"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.message}
              ></textarea>
              {formik.touched.message && formik.errors.message ? (
                <p className="help is-danger is-size-6">
                  {formik.errors.message}
                </p>
              ) : null}
            </div>
          </div>

          <div className="field is-grouped">
            <div className="control control-mobile">
              <button type="submit" className="btn-primary">
                إرسال
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default Form
